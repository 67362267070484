import { ContactTemplate } from '../en-ca/contact'

export default ContactTemplate(
  [
    {
      node_locale: 'en-CA',
      relativePath: '/en-ca/contact',
    },
  ],
  {
    seo: {
      title: 'Contact | CRG',
      description: {
        description:
          'Canada Rubber Group est un fabricant canadien de premier plan spécialisé dans la fabrication sur mesure de pièces en caoutchouc, en plastique, en silicone, en mousse et plus encore.',
      },
    },
  }
)
