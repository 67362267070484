import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import * as Accessa from 'accessa'
import { useTranslation } from 'react-i18next'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
} from '@material-ui/core'
import {
  Email,
  Explore,
  LocalPhone,
  SettingsEthernet,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { ArchiveLayout } from '../components'
import { getLocale } from '../utils/i18n-helpers'

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    boxShadow: theme.shadows[3],
    marginBottom: 1,
  },
  map: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 400,
    },
  },
}))

const ContactListItem = ({ href, label, value }) => {
  const { t } = useTranslation('labels')
  const { root } = useStyle()
  return (
    <li>
      <ListItem
        button
        className={root}
        component="a"
        href={href}
      >
        <ListItemText
          primary={t(label)}
          secondary={<u>{value}</u>}
        />
      </ListItem>
    </li>
  )
}

ContactListItem.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

const ContactGridItem = ({ children, title }) => {
  const { t } = useTranslation('titles')
  return (
    <Grid item md={6} xs={12}>
      <Typography
        component="h2"
        variant="overline"
        style={{ textTransform: 'none' }}
        gutterBottom
      >
        {t(title)}
      </Typography>
      {children}
    </Grid>
  )
}

ContactGridItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
  title: PropTypes.string.isRequired,
}

const Contact = () => {
  const { t } = useTranslation()
  const { map } = useStyle()

  const {
    allContentfulContact: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulContact {
        nodes {
          node_locale
          email
          streetLine
          city
          postalCode
          tollFreePhoneNumber
          phoneNumber
          fax
          hours {
            branchName
            rule
            exception
            contentful_id
          }
        }
      }
      contentfulNavigation(
        contentful_id: { eq: "77FEHqU1r3twQbrvjme98z" }
      ) {
        pages {
          ... on ContentfulPage {
            title
          }
        }
      }
    }
  `)

  const {
    email,
    streetLine,
    city,
    postalCode,
    tollFreePhoneNumber,
    phoneNumber,
    fax,
    hours = [],
  } =
    nodes.find((n) =>
      n.node_locale.startsWith(getLocale())
    ) || {}

  return (
    <ArchiveLayout
      title={t('titles:contact')}
      focalComponent={
        <Box className={map}>
          <Accessa.Partials.Compass
            apiKey="AIzaSyCt7yombMtPIeU_-mWAi4_3iuOfh-Z_LY0"
            street={streetLine}
            city={city}
            postal={postalCode}
          />
        </Box>
      }
      reverseOnMobile
    >
      <Box my={1}>
        <Grid container spacing={5}>
          <ContactGridItem title="waysToGetInTouch">
            <Typography gutterBottom>
              {t('descriptions:inquiries')}
            </Typography>
            <List>
              <ContactListItem
                icon={Email}
                label="email"
                value={email}
                href={`mailTo:${email}`}
              />
              <ContactListItem
                icon={Explore}
                label="northAmerica"
                value={tollFreePhoneNumber}
                href={`tel:${tollFreePhoneNumber}`}
              />
              <ContactListItem
                icon={LocalPhone}
                label="local"
                value={phoneNumber}
                href={`tel:${phoneNumber}`}
              />
              {/* <ContactListItem
                icon={SettingsEthernet}
                label="fax"
                value={fax}
                href={`tel:${fax}`}
              /> */}
            </List>
          </ContactGridItem>
          <ContactGridItem title="hoursOfOperation">
            <Grid container spacing={5}>
              {hours.map((item) => (
                <Grid item key={item.contentful_id}>
                  <Typography component="h3" variant="h6">
                    {item.branchName}
                  </Typography>
                  <Typography>{item.rule}</Typography>
                  <Typography>{item.exception}</Typography>
                </Grid>
              ))}
            </Grid>
          </ContactGridItem>
        </Grid>
      </Box>
    </ArchiveLayout>
  )
}

Contact.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line
    contentfulIndex: PropTypes.object,
  }).isRequired,
}

export default Contact
