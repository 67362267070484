import { makeTemplateWithGlobal } from '../../components/withGlobals'
import Contact from '../../templates/contact'

export const ContactTemplate = makeTemplateWithGlobal(
  Contact
)
export default ContactTemplate(
  [
    {
      node_locale: 'fr-CA',
      relativePath: '/fr-ca/contact',
    },
  ],
  {
    seo: {
      title: 'Contact | CRG',
      description: {
        description:
          'Canada Rubber Group is a leading Canadian manufacturer specializing in custom manufacturing of parts made from rubber, plastics, silicone, foams and more.',
      },
    },
  }
)
